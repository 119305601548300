import { useEffect } from 'react';
import camelcaseKeys from 'camelcase-keys';

import {
  GAMEROOM_CLOSED,
  GAMEROOM_PLAYER_JOINED,
  GAMEROOM_PLAYER_LEFT,
  GAMEROOM_PICKING_NEW_PACK,
  GAME_CANCELED,
  GAME_STARTED,
  GAME_ROUND_STARTED,
  GAME_ROUND_FINISHED,
  GAME_ROUND_ANSWER_CREATED,
  GAME_FINISHED,
} from '../constants/events';

import paths from '../constants/paths';

import useCableStore from '../stores/useCableStore';

import useGameroomStore from '../stores/useGameroomStore';
import useNavigationStore from '../stores/useNavigationStore';
import usePlayerStore from '../stores/usePlayerStore';

import useClearGameState from './useClearGameState';

const useConnectCable = (gameroomKey) => {
  const create = useCableStore((state) => state.create);
  const subscribe = useCableStore((state) => state.subscribe);
  const message = useCableStore((state) => state.message);
  const clearMessage = useCableStore((state) => state.clearMessage);

  const playerInfo = usePlayerStore((state) => state.playerInfo);

  const navigateTo = useNavigationStore((state) => state.navigateTo);

  const addPlayerToGameroom = useGameroomStore(
    (state) => state.addPlayerToGameroom
  );
  const addCurrentGameToGameroom = useGameroomStore(
    (state) => state.addCurrentGameToGameroom
  );
  const addRoundToGameroom = useGameroomStore(
    (state) => state.addRoundToGameroom
  );
  const finishGameroomCurrentRound = useGameroomStore(
    (state) => state.finishGameroomCurrentRound
  );
  const addAnswerToGameroom = useGameroomStore(
    (state) => state.addAnswerToGameroom
  );
  const removePlayerFromGameroom = useGameroomStore(
    (state) => state.removePlayerFromGameroom
  );

  const setGameroomFeedBackMessage = useGameroomStore(
    (state) => state.setGameroomFeedBackMessage
  );

  const clearGameState = useClearGameState();

  useEffect(() => {
    create();
    if (playerInfo && gameroomKey) {
      subscribe(gameroomKey, playerInfo.id);
    }
  }, [playerInfo, gameroomKey]);

  useEffect(() => {
    if (message) {
      const processedMessage = camelcaseKeys(message, { deep: true });

      switch (processedMessage?.event) {
        case GAMEROOM_PLAYER_JOINED:
          addPlayerToGameroom(processedMessage);
          break;
        case GAMEROOM_PLAYER_LEFT:
          removePlayerFromGameroom(processedMessage);
          if (processedMessage.id === playerInfo.id) navigateTo(paths.root);
          break;
        case GAMEROOM_PICKING_NEW_PACK:
          setGameroomFeedBackMessage('The game is starting...');
          break;
        case GAME_STARTED:
          addCurrentGameToGameroom(processedMessage);
          break;
        case GAME_ROUND_STARTED:
          navigateTo(paths.round);
          addRoundToGameroom(processedMessage);
          setGameroomFeedBackMessage('');
          break;
        case GAME_ROUND_FINISHED:
          navigateTo(paths.result);
          finishGameroomCurrentRound(processedMessage);
          break;
        case GAME_ROUND_ANSWER_CREATED:
          addAnswerToGameroom(processedMessage);
          if (processedMessage.playerId === playerInfo.id) {
            navigateTo(paths.answers);
          }
          break;
        case GAME_CANCELED:
        case GAME_FINISHED:
          navigateTo(paths.scores);
          addCurrentGameToGameroom(processedMessage);
          break;
        case GAMEROOM_CLOSED:
          clearGameState();
          break;
        default:
      }
      clearMessage();
    }
  }, [message?.event]);
};

export default useConnectCable;
