import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Text } from '@chakra-ui/react';

import LogOut from '../assets/Logout.svg';

const LeaveGameButton = ({ onClick, isAdmin }) => {
  return (
    <Flex
      onClick={onClick}
      marginBottom="3.2rem"
      alignItems="center"
      gap="1rem"
      _hover={{ cursor: 'pointer' }}
    >
      <img src={LogOut} alt="logout" />
      <Text
        whiteSpace="nowrap"
        textColor="secondary.pink.light"
        variant="leave"
      >
        {isAdmin ? 'finish game' : 'leave game'}
      </Text>
    </Flex>
  );
};

export default LeaveGameButton;

LeaveGameButton.propTypes = {
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
