const createRandomCopiesSlice = (set) => ({
  randomCopies: {
    rightAnswer: ["You're a genius!"],
    wrongAnswer: ['Oh snap!'],
    loading: ['Wait a few moments.'],
  },

  setRandomCopies: (randomCopies) =>
    set({ randomCopies }, undefined, 'randomCopies/setRandomCopies'),
});

export default createRandomCopiesSlice;
