import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'image',
  'stack',
  'text',
]);

const baseStyle = helpers.definePartsStyle(({ color }) => ({
  stack: {
    gap: '1.6rem',
    flexDir: 'column',
  },
  container: {
    borderRadius: '1rem',
    padding: '0.35rem',
    userSelect: 'none',
    width: '100%',
  },
  image: {
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '0.35rem',
    borderRadius: '1rem',
    borderColor: color,
    backgroundColor: color,
    width: '100%',
  },
  text: {
    fontSize: '1.8rem',
    fontWeight: 'semibold',
    textColor: 'white',
    bottom: '0',
    textAlign: 'center',
    lineHeight: '2rem',
    maxW: '14rem',
  },
}));

const variants = {
  selected: ({ secondaryColor }) => ({
    image: {
      borderColor: secondaryColor,
      cursor: 'pointer',
    },
    container: {
      backgroundColor: secondaryColor,
    },
  }),
  notSelected: {
    image: {
      cursor: 'pointer',
    },
    text: {
      fontWeight: 'regular',
    },
  },
};

const Persona = helpers.defineMultiStyleConfig({
  baseStyle,
  variants,
});

export default Persona;
