import { useMutation } from '@tanstack/react-query';

import { destroyGameroom } from '../api/gameroom';

import useClearGameState from './useClearGameState';

const useDestroyGameroom = () => {
  const clearGameState = useClearGameState();

  return useMutation({
    mutationFn: ({ gameroomKey }) => destroyGameroom(gameroomKey),
    onSettled: () => {
      clearGameState();
    },
  });
};

export default useDestroyGameroom;
