import createCurrentGameSlice from './createCurrentGameSlice';
import createGameroomSlice from './createGameroomSlice';
import createPlayersSlice from './createPlayersSlice';

const resetAllSlices = (set) => ({
  resetAll: () => {
    createGameroomSlice(set).reset();
    createCurrentGameSlice(set).reset();
    createPlayersSlice(set).reset();
  },
});

export default resetAllSlices;
