import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import useFetchGameroom from '../hooks/useFetchGameroom';
import useFetchRandomCopies from '../hooks/useFetchRandomCopies';
import useCableStore from '../stores/useCableStore';
import useNavigationStore from '../stores/useNavigationStore';
import useConnectCable from '../hooks/useConnectCable';

import useGameroomStore from '../stores/useGameroomStore';
import usePlayerStore from '../stores/usePlayerStore';

import Loading from './Loading';

const AppWrapper = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const isConnected = useCableStore((state) => state.isConnected);

  const handleNavigation = useNavigationStore(
    (state) => state.handleNavigation
  );
  const isNavigating = useNavigationStore((state) => state.isNavigating);
  const setIsNavigating = useNavigationStore((state) => state.setIsNavigating);
  const setNavigate = useNavigationStore((state) => state.setNavigate);

  const gameroom = useGameroomStore((state) => state.gameroom);
  const currentGame = useGameroomStore((state) => state.currentGame);
  const updateGameroom = useGameroomStore((state) => state.updateGameroom);
  const setRandomCopies = useGameroomStore((state) => state.setRandomCopies);

  const playerInfo = usePlayerStore((state) => state.playerInfo);

  const { data: fetchedGameroom, isLoading } = useFetchGameroom();
  const { data: fetchedRandomCopies } = useFetchRandomCopies();

  useConnectCable(fetchedGameroom?.gameroomKey);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate, setNavigate]);

  useEffect(() => {
    setIsNavigating(false);
  }, [location, setIsNavigating]);

  useEffect(() => {
    if (fetchedGameroom) {
      updateGameroom(fetchedGameroom);
    }
  }, [fetchedGameroom, updateGameroom]);

  useEffect(() => {
    if (fetchedRandomCopies) {
      setRandomCopies(fetchedRandomCopies);
    }
  }, [fetchedRandomCopies, setRandomCopies]);

  useEffect(() => {
    if (!isNavigating) {
      handleNavigation(
        gameroom,
        currentGame,
        playerInfo?.id,
        location.pathname
      );
    }
  }, [
    currentGame,
    gameroom,
    handleNavigation,
    isNavigating,
    location.pathname,
    playerInfo?.id,
  ]);

  if (isLoading || isNavigating) {
    return <Loading title="Loading..." />;
  }

  if (!isConnected && fetchedGameroom) {
    return (
      <Loading
        title="Trying to reconnect..."
        description="Make sure you're online."
        leaveGameButton
      />
    );
  }

  return <Outlet />;
};

export default AppWrapper;
