import React, { useState, useMemo } from 'react';
import {
  Center,
  Stack,
  Button,
  Box,
  Text,
  Flex,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import PlayerItem from '../components/PlayerItem';
import PlayerList from '../components/PlayerList';
import Modal from '../components/Modal';
import LeaveGameButton from '../components/LeaveGameButton';

import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useDestroyPlayer from '../hooks/useDestroyPlayer';

import useGameroomStore from '../stores/useGameroomStore';
import useNavigationStore from '../stores/useNavigationStore';
import usePlayerStore from '../stores/usePlayerStore';

import paths from '../constants/paths';
import { openModal, closedModal } from '../utils/modal';

const Scores = () => {
  const navigateTo = useNavigationStore((state) => state.navigateTo);
  const [modalInfo, setModalInfo] = useState(closedModal());
  const styles = useMultiStyleConfig('Leaderboard');

  const scores = useGameroomStore((state) => state.currentGame?.scores ?? {});

  const players = useGameroomStore((state) => {
    const currentGamePlayers = state.currentGame?.players ?? [];

    const filteredPlayers = currentGamePlayers.filter((player) => {
      const isInScores = scores[player.id] !== undefined;
      const hasLeftGameroom = !!player.leftGameroomAt;

      return isInScores || !hasLeftGameroom;
    });

    return filteredPlayers;
  });

  const gameroomKey = useGameroomStore((state) => state.gameroom.gameroomKey);
  const gameroomFeedBackMessage = useGameroomStore(
    (state) => state.gameroomFeedBackMessage
  );

  const playerInfo = usePlayerStore((state) => state.playerInfo);

  const { id: playerId, username, admin } = playerInfo;

  const { mutate: destroyGameroom } = useDestroyGameroom();

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const handleLeave = () => {
    if (admin) {
      destroyGameroom({ gameroomKey });
    } else {
      destroyPlayer({ gameroomKey, playerId });
    }
  };
  const handlePlayAgain = () => {
    navigateTo(paths.packs);
  };

  const hasValidScore = (p) =>
    scores[p.id] !== null && scores[p.id] !== undefined;

  const sortedPlayers = useMemo(() => {
    const validPlayers = players.filter(hasValidScore);
    const invalidPlayers = players.filter((p) => !hasValidScore(p));

    const sortedValidPlayers = [...validPlayers].sort((a, b) => {
      const diff = scores[b.id] - scores[a.id];

      return diff === 0 ? a.username.localeCompare(b.username) : diff;
    });

    const highestScore = Math.max(
      ...sortedValidPlayers.map((p) => scores[p.id])
    );
    const lowestScore = Math.min(
      ...sortedValidPlayers.map((p) => scores[p.id])
    );

    const positionedValidPlayers = sortedValidPlayers.map((p) => {
      let position;
      const playerScore = scores[p.id] || 0;

      if (highestScore === lowestScore || playerScore === lowestScore) {
        position = 'lastPlace';
      } else if (playerScore === highestScore) {
        position = 'firstPlace';
      } else {
        position = 'other';
      }
      return {
        ...p,
        isCurrentPlayer: username === p.username,
        roomProps: {
          value: scores[p.id] ?? '-',
          position,
        },
        isAdmin: p.admin,
        variant: 'leaderBoard',
      };
    });

    const positionedInvalidPlayers = invalidPlayers.map((p) => ({
      ...p,
      isCurrentPlayer: username === p.username,
      roomProps: {
        value: '-',
        position: 'other',
      },
      isAdmin: p.admin,
      variant: 'leaderBoard',
    }));

    return [...positionedValidPlayers, ...positionedInvalidPlayers];
  }, [players]);

  const firstPlacePlayers = sortedPlayers.filter(
    (player) => player.roomProps.position === 'firstPlace'
  );

  const otherPlayers = sortedPlayers.filter(
    (player) => player.roomProps.position !== 'firstPlace'
  );

  return (
    <Center minHeight="100vh" minWidth="fit-content">
      <Flex sx={{ ...styles.container }}>
        <Stack sx={{ ...styles.background }}>
          <Stack sx={{ ...styles.content }}>
            <Stack sx={{ ...styles.title }}>
              <Text variant="completed">Pack completed</Text>
              <Text variant="pageTitle">Scores</Text>
            </Stack>
            {firstPlacePlayers && (
              <Flex sx={styles.winnerContainer}>
                {firstPlacePlayers.map((player, index) => (
                  <Box key={index} sx={styles.winner}>
                    <PlayerItem {...player} variant="leaderBoard" />
                  </Box>
                ))}
              </Flex>
            )}
            <PlayerList variant="leaderBoard" players={otherPlayers} />
            {admin && (
              <Stack sx={styles.adminButtons}>
                <Button
                  variant="solid"
                  scheme="main.purple"
                  onClick={handlePlayAgain}
                >
                  Play again
                </Button>
                <Button
                  variant="outline"
                  scheme="secondary.pink"
                  onClick={handleLeave}
                >
                  Close room
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
        {!admin && (
          <>
            <Text sx={styles.footer} variant="footer">
              {gameroomFeedBackMessage}
            </Text>
            <LeaveGameButton
              onClick={() => setModalInfo(openModal('leave_game', handleLeave))}
            />
          </>
        )}
      </Flex>

      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default Scores;
