import React, { useState } from 'react';
import { Stack, Button, Center } from '@chakra-ui/react';

import Loading from '../components/Loading';
import TitleDescription from '../components/TitleDescription';
import Input from '../components/Input';

import useJoinGameroom from '../hooks/useJoinGameroom';

import useNavigationStore from '../stores/useNavigationStore';

import paths from '../constants/paths';

const JoinRoom = () => {
  const navigateTo = useNavigationStore((state) => state.navigateTo);

  const [gameroomKey, setGameroomKey] = useState('');
  const [username, setUsername] = useState('');
  const [gameroomError, setGameroomError] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const handleErrors = (error) => {
    if (error.response && error.response.status === 422) {
      const { data } = error.response;

      if (data.errors.username) {
        setUsernameError(data.errors.username[0]);
      }
      if (data.errors.gameroom) {
        setGameroomError(data.errors.gameroom[0]);
      }
    }
  };

  const { mutate: joinGameroom, isPending: isJoining } = useJoinGameroom({
    onSuccess: () => {
      navigateTo(paths.awaiting);
    },
    onError: (error) => handleErrors(error),
  });

  const onGameroomKeyChange = (event) => {
    setGameroomKey(event.target.value);
    setGameroomError('');
  };

  const onUsernameChange = (event) => {
    setUsername(event.target.value);
    setUsernameError('');
  };

  const handleJoinRoom = async () => {
    joinGameroom({ username, gameroomKey });
  };

  return (
    <Center minHeight="100vh" minWidth="fit-content">
      {isJoining ? (
        <Loading title="Loading..." />
      ) : (
        <Stack
          bg="main.purple.darker"
          gap="1rem"
          maxWidth="34.3rem"
          borderRadius="2rem"
          paddingY="2.4rem"
          paddingX="3.2rem"
        >
          <Stack maxWidth="27.9rem" gap="4.8rem">
            <Stack gap="2.8rem">
              <TitleDescription
                title="Join room"
                description="If you don't know the room code, ask the players that already joined."
              />
              <Input
                label="Room code"
                placeholder="GUS20"
                value={gameroomKey}
                onChange={onGameroomKeyChange}
                errors={gameroomError}
              />
              <Input
                label="Username"
                placeholder="gustavinho"
                value={username}
                onChange={onUsernameChange}
                errors={usernameError}
              />
            </Stack>
            <Stack gap="0.8rem">
              <Button
                variant="solid"
                scheme="main.purple"
                onClick={handleJoinRoom}
              >
                Join room
              </Button>
              <Button
                variant="outline"
                scheme="main.purple"
                onClick={() => navigateTo(paths.root)}
              >
                Back
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Center>
  );
};

export default JoinRoom;
