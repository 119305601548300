import { useQuery } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';

import getRandomCopies from '../api/randomCopies';

const useFetchRandomCopies = () => {
  return useQuery({
    queryKey: ['randomCopies'],
    queryFn: async () => {
      const data = await getRandomCopies();
      const groupedData = data.reduce((acc, { copyType, text }) => {
        if (!acc[copyType]) {
          acc[copyType] = [];
        }
        acc[copyType].push(text);
        return acc;
      }, {});

      return camelcaseKeys(groupedData, { deep: true });
    },
    staleTime: Infinity,
  });
};

export default useFetchRandomCopies;
