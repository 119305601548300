import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import createPlayersSlice from './gameroomSlices/createPlayersSlice';
import createCurrentGameSlice from './gameroomSlices/createCurrentGameSlice';
import createGameroomSlice from './gameroomSlices/createGameroomSlice';
import createRandomCopiesSlice from './gameroomSlices/createRandomCopiesSlice';
import resetAllSlices from './gameroomSlices/resetAllSlices';

const useGameroomStore = create(
  devtools(
    persist(
      (...a) => ({
        ...createPlayersSlice(...a),
        ...createCurrentGameSlice(...a),
        ...createGameroomSlice(...a),
        ...createRandomCopiesSlice(...a),
        ...resetAllSlices(...a),
      }),
      {
        name: 'gameroom',
        partialize: (state) => ({
          gameroom: state.gameroom,
          gameroomFeedBackMessage: state.gameroomFeedBackMessage,
          randomCopies: state.randomCopies,
        }),
      }
    ),
    { name: 'GameroomStore' }
  )
);

export default useGameroomStore;
