import paths from '../constants/paths';

import useCableStore from '../stores/useCableStore';
import useGameroomStore from '../stores/useGameroomStore';
import usePlayerStore from '../stores/usePlayerStore';
import useNavigationStore from '../stores/useNavigationStore';

const useClearGameState = () => {
  const destroy = useCableStore((state) => state.destroy);
  const resetGameroomState = useGameroomStore((state) => state.resetAll);
  const resetPlayerInfoState = usePlayerStore((state) => state.reset);
  const navigateTo = useNavigationStore((state) => state.navigateTo);

  const clearGameState = () => {
    navigateTo(paths.root);
    destroy();
    resetGameroomState();
    resetPlayerInfoState();
  };

  return clearGameState;
};

export default useClearGameState;
