const updatePlayers = (state, updateFn) => {
  const updatedGameroomPlayers = updateFn(state.players);
  const updatedCurrentGamePlayers = state.currentGame
    ? updateFn(state.currentGame.players)
    : [];

  return {
    players: updatedGameroomPlayers,
    currentGame: state.currentGame
      ? { ...state.currentGame, players: updatedCurrentGamePlayers }
      : state.currentGame,
  };
};

const createPlayersSlice = (set) => ({
  players: [],

  addPlayerToGameroom: (player) =>
    set(
      (state) => {
        const updatedPlayers = updatePlayers(state, (players) => [
          ...players,
          player,
        ]);
        return {
          ...state,
          players: updatedPlayers.players,
          currentGame: updatedPlayers.currentGame,
        };
      },
      undefined,
      'players/addPlayerToGameroom'
    ),

  removePlayerFromGameroom: (player) =>
    set(
      (state) => {
        const hasCurrentGame = !!state.currentGame;
        const updatedPlayers = updatePlayers(state, (players) =>
          hasCurrentGame
            ? players.map((p) =>
                p.username === player.username
                  ? { ...p, leftGameroomAt: new Date().toISOString() }
                  : p
              )
            : players.filter((p) => p.username !== player.username)
        );

        return {
          ...state,
          players: updatedPlayers.players,
          currentGame: updatedPlayers.currentGame,
        };
      },
      undefined,
      'players/removePlayerFromGameroom'
    ),

  reset: () =>
    set(
      () => ({
        players: [],
      }),
      undefined,
      'players/reset'
    ),
});

export default createPlayersSlice;
