import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'gradientBorder',
  'container',
  'textContainer',
  'personaName',
  'textVS',
  'image',
]);

const Pack = helpers.defineMultiStyleConfig({
  baseStyle: {
    gradientBorder: {
      position: 'relative',
      zIndex: '0',
      background: 'main.purple.darker',
      borderRadius: '2rem',
      cursor: 'pointer',
      width: '42rem',
    },
    container: {
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '3.2rem',
      borderRadius: '2rem',
      gap: '3.2rem',
    },
    textContainer: {
      minWidth: '12rem',
      marginY: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.8rem',
    },
    personaName: {
      textColor: 'white',
      fontWeight: 400,
      fontSize: '1.6rem',
    },
    textVS: {
      textColor: 'main.purple.light',
      fontSize: '2.4rem',
      lineHeight: '3.5rem',
      fontWeight: '600',
      textAlign: 'center',
    },
    image: {
      width: '19.5rem',
      objectFit: 'fill',
    },
  },

  variants: {
    selected: ({ colors }) => ({
      gradientBorder: {
        _before: {
          borderRadius: '2rem',
          content: '""',
          position: 'absolute',
          zIndex: -1,
          padding: '0.5rem',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          WebkitMask:
            'linear-gradient(white 0 0) content-box, linear-gradient(white 0 0)',
          mask: 'linear-gradient(black 0 0) content-box, linear-gradient(white 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
          background: `linear-gradient(to bottom right, ${colors[0]}, ${colors[1]})`,
        },
      },
    }),
  },
});

export default Pack;
