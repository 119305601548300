const createCurrentGameSlice = (set) => ({
  currentGame: null,

  addCurrentGameToGameroom: (currentGame) =>
    set(
      (state) => ({
        ...state,
        currentGame,
        players: currentGame.players,
      }),
      undefined,
      'currentGame/addCurrentGameToGameroom'
    ),

  addAnswerToGameroom: (answer) =>
    set(
      (state) => {
        if (!state.currentGame) return state;

        const updatedRounds = state.currentGame.rounds.map(
          (round, index, rounds) =>
            index === rounds.length - 1
              ? { ...round, answers: [...round.answers, answer] }
              : round
        );

        return {
          ...state,
          currentGame: { ...state.currentGame, rounds: updatedRounds },
        };
      },
      undefined,
      'currentGame/addAnswerToGameroom'
    ),

  finishGameroomCurrentRound: (round) =>
    set(
      (state) => {
        const { currentGame } = state;
        if (!currentGame) return state;

        const updatedRounds = currentGame.rounds.map((r, index, rounds) =>
          index === rounds.length - 1 ? round : r
        );

        return {
          ...state,
          currentGame: { ...currentGame, rounds: updatedRounds },
        };
      },
      undefined,
      'currentGame/finishGameroomCurrentRound'
    ),

  addRoundToGameroom: (round) =>
    set(
      (state) => ({
        ...state,
        currentGame: {
          ...state.currentGame,
          rounds: [...(state.currentGame?.rounds || []), round],
        },
      }),
      undefined,
      'currentGame/addRoundToGameroom'
    ),

  reset: () =>
    set(
      () => ({
        currentGame: null,
      }),
      undefined,
      'currentGame/reset'
    ),
});

export default createCurrentGameSlice;
