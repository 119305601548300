import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, useMultiStyleConfig } from '@chakra-ui/react';

import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useDestroyPlayer from '../hooks/useDestroyPlayer';

import usePlayerStore from '../stores/usePlayerStore';
import useGameroomStore from '../stores/useGameroomStore';

import TitleDescription from './TitleDescription';
import BubbleAnimation from './animations/BubbleAnimation';

const Loading = ({ title, description, leaveGameButton }) => {
  const styles = useMultiStyleConfig('Loading');

  const playerInfo = usePlayerStore((state) => state.playerInfo);
  const { id: playerId, admin } = playerInfo || {};

  const gameroomKey = useGameroomStore((state) => state.gameroom?.gameroomKey);
  const loadingCopies = useGameroomStore((state) => state.randomCopies.loading);

  const { mutate: destroyGameroom } = useDestroyGameroom();

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const handleLeave = () => {
    if (admin) {
      destroyGameroom({ gameroomKey });
    } else {
      destroyPlayer({ gameroomKey, playerId });
    }
  };

  const getRandomLoadingCopy = () => {
    const randomIndex = Math.floor(Math.random() * loadingCopies.length);
    return loadingCopies[randomIndex];
  };

  return (
    <>
      <Box sx={{ ...styles.container }}>
        <Box sx={{ ...styles.animationContainer }}>
          <BubbleAnimation />
        </Box>
        <Box sx={{ ...styles.content }}>
          <TitleDescription
            title={title}
            description={description || getRandomLoadingCopy()}
            variant="loading"
          />
          {leaveGameButton && (
            <Button
              variant="outline"
              scheme="main.purple"
              onClick={handleLeave}
            >
              LEAVE GAME
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

Loading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  leaveGameButton: PropTypes.bool,
};

export default Loading;
