import { useMutation } from '@tanstack/react-query';

import { destroyPlayer } from '../api/player';

import useClearGameState from './useClearGameState';

const useDestroyPlayer = () => {
  const clearGameState = useClearGameState();

  return useMutation({
    mutationFn: ({ gameroomKey, playerId }) =>
      destroyPlayer(gameroomKey, playerId),
    onSettled: () => {
      clearGameState();
    },
  });
};

export default useDestroyPlayer;
