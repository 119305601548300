import { useQuery } from '@tanstack/react-query';

import { fetchGameroom } from '../api/gameroom';

import useGameroomStore from '../stores/useGameroomStore';

const useFetchGameroom = () => {
  const gameroomKey = useGameroomStore.getState().gameroom?.gameroomKey;

  return useQuery({
    queryKey: ['fetchGameroom', gameroomKey],
    queryFn: async () => {
      const data = await fetchGameroom(gameroomKey);

      if (data?.currentGame?.rounds) {
        data.currentGame.rounds = [...data.currentGame.rounds].sort(
          (a, b) => a.id - b.id
        );
      }

      return data;
    },
    enabled: !!gameroomKey,
  });
};

export default useFetchGameroom;
