import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Home from '../pages/Home';
import CreateRoom from '../pages/CreateRoom';
import JoinRoom from '../pages/JoinRoom';
import Result from '../pages/Result';
import Round from '../pages/Round';
import Scores from '../pages/Scores';
import AwaitingPlayers from '../pages/AwaitingPlayers';
import PackSelection from '../pages/PackSelection';
import Answers from '../pages/Answers';

import AppWrapper from '../components/AppWrapper';
import GameroomLayout from '../components/GameroomLayout';

import paths from '../constants/paths';

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppWrapper />}>
      <Route path={paths.root} element={<Home />} />
      <Route path={paths.joinGameroom} element={<JoinRoom />} />
      <Route path={paths.createGameroom} element={<CreateRoom />} />
      <Route element={<GameroomLayout />}>
        <Route path={paths.awaiting} element={<AwaitingPlayers />} />
        <Route path={paths.result} element={<Result />} />
        <Route path={paths.round} element={<Round />} />
        <Route path={paths.answers} element={<Answers />} />
        <Route path={paths.packs} element={<PackSelection />} />
        <Route path={paths.scores} element={<Scores />} />
      </Route>
    </Route>
  )
);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};

export default App;
