import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

const usePlayerStore = create(
  devtools(
    persist(
      (set) => ({
        deviceUuid: null,
        playerInfo: null,
        skipModal: false,

        setDeviceUuid: (deviceUuid) => {
          set({ deviceUuid }, undefined, 'player/setDeviceUuid');
        },

        setPlayerInfo: (playerInfo) => {
          set({ playerInfo }, undefined, 'player/playerInfo');
        },

        setSkipModal: (skipModal) => {
          set({ skipModal }, undefined, 'player/skipModal');
        },
        reset: () => {
          set(
            { deviceUuid: null, playerInfo: null, skipModal: false },
            undefined,
            'player/reset'
          );
        },
      }),
      { name: 'playerInfo' }
    ),
    { name: 'PlayerStore' }
  )
);

export default usePlayerStore;
