import { useQuery } from '@tanstack/react-query';

import getPacks from '../api/packs';

import useGameroomStore from '../stores/useGameroomStore';

const useGetPacks = () => {
  const { gameroomKey } = useGameroomStore.getState().gameroom;

  return useQuery({
    queryKey: ['packs', gameroomKey],
    queryFn: async () => {
      const packs = await getPacks(gameroomKey);

      return packs.sort((a, b) => a.position - b.position);
    },
  });
};

export default useGetPacks;
