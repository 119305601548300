import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper/modules';

import { Box, Circle } from '@chakra-ui/react';

import PropTypes from 'prop-types';

import Pack from './Pack';

import { PackShape } from '@utils/propsShape';

const PackSlider = ({ packs, selectedPack, onClick }) => {
  const sliderRef = useRef(null);
  const [index, setIndex] = useState(0);

  return (
    <Box position="relative">
      <Box mx="auto">
        <Swiper
          direction="vertical"
          spaceBetween={16}
          slidesPerView={3}
          onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
          allowTouchMove={true}
          speed={500}
          style={{
            height: '57rem',
          }}
          modules={[Mousewheel]}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          onSwiper={(swiper) => {
            sliderRef.current = swiper;
          }}
        >
          {packs.map((pack, i) => (
            <SwiperSlide key={i}>
              <Pack
                pack={pack}
                onClick={() => onClick(i)}
                variant={selectedPack === i ? 'selected' : ''}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Box
          className="custom-dots"
          position="absolute"
          top="50%"
          right="-2.4rem"
          transform="translateY(-50%)"
          display="flex"
          flexDirection="column"
          cursor="pointer"
        >
          {packs.map((_, i) => (
            <Circle
              key={i}
              size="1rem"
              bg={
                i >= index && i < index + 3
                  ? 'secondary.yellow.base'
                  : 'main.purple.dark'
              }
              onClick={() => {
                sliderRef.current.slideTo(i);
              }}
              m="0.5rem 0"
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

PackSlider.propTypes = {
  packs: PropTypes.arrayOf(PackShape),
  selectedPack: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default PackSlider;
