import { useMutation } from '@tanstack/react-query';

import { createGame } from '../api/game';
import { buildNextRound } from '../api/round';

import useGameroomStore from '../stores/useGameroomStore';

const useCreateGame = () => {
  return useMutation({
    mutationFn: async (packId) => {
      const { gameroomKey } = useGameroomStore.getState().gameroom;
      const game = await createGame(gameroomKey, packId);
      await buildNextRound(game.id);
    },
  });
};

export default useCreateGame;
