import paths from '../constants/paths';

const getNavigationPath = (gameroom, currentGame, playerId, currentPath) => {
  if (!gameroom || !playerId) return null;

  if (!currentGame) {
    if (![paths.awaiting, paths.packs].includes(currentPath)) {
      return paths.awaiting;
    }
    return null;
  }

  const { rounds, scores } = currentGame;

  if (scores) {
    if (![paths.scores, paths.packs].includes(currentPath)) {
      return paths.scores;
    }
    return null;
  }

  const currentRound = rounds[rounds.length - 1];

  if (currentRound) {
    if (currentRound.finished) {
      if (currentPath !== paths.result) {
        return paths.result;
      }
      return null;
    }

    const hasPlayerAnswered = currentRound.answers.some(
      (answer) => answer.playerId === Number(playerId)
    );

    if (hasPlayerAnswered) {
      if (currentPath !== paths.answers) {
        return paths.answers;
      }
      return null;
    }

    if (currentPath !== paths.round) {
      return paths.round;
    }

    return null;
  }

  return null;
};

export default getNavigationPath;
