import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createConsumer } from '@rails/actioncable';

import usePlayerStore from './usePlayerStore';

const useCableStore = create(
  devtools(
    (set) => ({
      consumer: null,
      channel: null,
      message: null,
      isConnected: false,

      create: () =>
        set(
          (state) => {
            const { deviceUuid } = usePlayerStore.getState();

            if (!state.consumer && deviceUuid) {
              const consumer = createConsumer(
                `${import.meta.env.VITE_API_WEBSOCKET_URL}?device_uuid=${deviceUuid}`
              );
              return { ...state, consumer };
            }

            return state;
          },
          undefined,
          'cable/create'
        ),

      clearMessage: () =>
        set(
          (state) => {
            return { ...state, message: null };
          },
          undefined,
          'cable/clearMessage'
        ),

      subscribe: (gameroomKey, playerId) =>
        set(
          (state) => {
            if (!state.channel) {
              const channel = state.consumer.subscriptions.create(
                {
                  channel: 'GameroomChannel',
                  gameroom_key: gameroomKey,
                  player_id: playerId,
                  version: 'v2',
                },
                {
                  received: (message) => {
                    state.setMessage(message);
                  },
                  disconnected: () => {
                    set(
                      { isConnected: false },
                      undefined,
                      'cable/disconnected'
                    );
                  },
                  connected: () => {
                    set({ isConnected: true }, undefined, 'cable/connected');
                  },
                }
              );
              return { ...state, channel };
            }
            return state;
          },
          undefined,
          'cable/subscribe'
        ),

      destroy: () =>
        set(
          (state) => {
            if (state.channel) {
              state.channel.unsubscribe();
            }
            if (state.consumer) {
              state.consumer.disconnect();
            }
            return { channel: null, consumer: null, isConnected: false };
          },
          undefined,
          'cable/destroy'
        ),

      setMessage: (message) =>
        set((state) => ({ ...state, message }), undefined, 'cable/setMessage'),
    }),
    { name: 'CableStore' }
  )
);

export default useCableStore;
