import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import getNavigationPath from '../utils/navigation';

const useNavigationStore = create(
  devtools(
    (set, get) => ({
      isNavigating: false,
      navigate: null,

      setNavigate: (navigate) =>
        set({ navigate }, undefined, 'navigation/setNavigate'),

      navigateTo: (path) => {
        set(
          (state) => {
            state.navigate(path);

            return { ...state, isNavigating: true };
          },
          undefined,
          'navigation/navigateTo'
        );
      },

      setIsNavigating: (isNavigating) =>
        set({ isNavigating }, undefined, 'navigation/setIsNavigating'),

      handleNavigation: (gameroom, currentGame, playerId, currentPath) => {
        const { navigateTo } = get();

        const path = getNavigationPath(
          gameroom,
          currentGame,
          playerId,
          currentPath
        );

        if (path && path !== currentPath) {
          navigateTo(path);
        }
      },
    }),
    { name: 'NavigationStore' }
  )
);

export default useNavigationStore;
