import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

const PlayerList = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: '100%',
      maxH: '18.274rem',
      css: {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      overflowY: 'scroll',
      overflowX: 'hidden',
      gap: '0.8rem',
    },
  },
  variants: {
    leaderBoard: {
      container: {
        maxH: 'none',
        paddingX: '3.2rem',
        maxW: '35.5rem',
      },
    },
  },
});

export default PlayerList;
