import React from 'react';
import { Image, Text, Flex, Box, useMultiStyleConfig } from '@chakra-ui/react';

import personaPlaceholder from '../assets/placeholders/persona.svg';

import { PersonaShape } from '@utils/propsShape';

const Persona = ({
  name,
  avatar,
  color = 'main.purple.light',
  secondaryColor,
  variant,
  onImageClick,
}) => {
  const styles = useMultiStyleConfig('Persona', {
    variant,
    secondaryColor,
    color,
  });

  return (
    <Flex sx={styles.stack}>
      <Box sx={styles.container}>
        <Image
          variant={variant}
          sx={styles.image}
          onClick={onImageClick}
          src={avatar}
          fallbackSrc={personaPlaceholder}
        />
      </Box>
      <Text sx={styles.text}>{name || '...'}</Text>
    </Flex>
  );
};

Persona.propTypes = PersonaShape;

export default Persona;
