import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import paths from '../constants/paths';

import useGameroomStore from '../stores/useGameroomStore';

const GameroomLayout = () => {
  const gameroom = useGameroomStore((state) => state.gameroom);

  if (!gameroom) {
    return <Navigate to={paths.root} />;
  }

  return <Outlet />;
};

export default GameroomLayout;
