import { useMutation } from '@tanstack/react-query';

import { createPlayer } from '../api/player';
import createDevice from '../api/device';

import usePlayerStore from '../stores/usePlayerStore';
import useGameroomStore from '../stores/useGameroomStore';

const useJoinGameroom = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async ({ username, gameroomKey }) => {
      const { deviceUuid } = usePlayerStore.getState();
      const device = await createDevice(deviceUuid);
      const player = await createPlayer(gameroomKey, {
        username,
        deviceId: device.id,
      });

      return { player, device };
    },
    onSuccess: ({ player, device }) => {
      const { setGameroomKey } = useGameroomStore.getState();
      const { setDeviceUuid, setPlayerInfo } = usePlayerStore.getState();

      setDeviceUuid(device.deviceUuid);
      setPlayerInfo({
        id: player.id,
        username: player.username,
        admin: player.admin,
      });

      setGameroomKey(player.gameroomKey);
      onSuccess();
    },
    onError,
  });
};
export default useJoinGameroom;
