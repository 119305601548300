import React, { useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Flex,
  Grid,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import Persona from '../components/Persona';
import LeaveGameButton from '../components/LeaveGameButton';
import Modal from '../components/Modal';

import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useSubmitAnswer from '../hooks/useSubmitAnswer';
import useCancelGame from '../hooks/useCancelGame';

import useGameroomStore from '../stores/useGameroomStore';

import { closedModal, openModal } from '../utils/modal';
import usePlayerStore from '../stores/usePlayerStore';

const Round = () => {
  const styles = useMultiStyleConfig('GameCard', { variant: 'round' });

  const [selectedPersona, setSelectedPersona] = useState(null);
  const playerInfo = usePlayerStore((state) => state.playerInfo);
  const gameroomKey = useGameroomStore((state) => state.gameroom.gameroomKey);

  const [modalInfo, setModalInfo] = useState(closedModal());

  const {
    id: gameId,
    length: numberRounds,
    rounds: {
      length: currentRoundNumber,
      [currentRoundNumber - 1]: currentRound,
    },
    pack,
  } = useGameroomStore((state) => state.currentGame);

  const { mutate: submitAnswer } = useSubmitAnswer();

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const { mutate: cancelGame } = useCancelGame();

  const handleSubmitAnswer = () => {
    if (!selectedPersona) {
      return;
    }

    const { id } = playerInfo;
    const roundId = currentRound.id;
    const answerParams = {
      playerId: id,
      personaId: selectedPersona,
    };
    submitAnswer({ roundId, answerParams });
  };

  const handleLeave = () => {
    if (playerInfo.admin) {
      cancelGame({ gameroomKey, gameId });
    } else {
      destroyPlayer({ gameroomKey, playerId: playerInfo.id });
    }
  };

  const handleOpenModal = () => {
    if (playerInfo.admin) {
      setModalInfo(openModal('end_game', handleLeave));
    } else {
      setModalInfo(openModal('leave_game', handleLeave));
    }
  };

  return (
    <Grid templateRows="1fr auto 1fr" sx={styles.grid}>
      <Text variant="count" alignSelf="end">
        {currentRoundNumber}/{numberRounds}
      </Text>
      <Stack sx={styles.card}>
        <Text variant="quote">“{currentRound.quote}“</Text>
        <Stack gap="3.2rem" align="center" width="100%">
          <Text textColor="white" variant="whoSaidIt">
            Who said it?
          </Text>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap="3.3rem"
            width="100%"
            paddingX="2.2rem"
          >
            <Persona
              name={pack.personaA.name}
              avatar={pack.personaA.avatar}
              color={pack.personaA.color}
              secondaryColor={pack.personaA.secondaryColor}
              onImageClick={() => setSelectedPersona(pack.personaA.id)}
              variant={
                selectedPersona === pack.personaA.id
                  ? 'selected'
                  : 'notSelected'
              }
            />
            <Persona
              name={pack.personaB.name}
              avatar={pack.personaB.avatar}
              color={pack.personaB.color}
              secondaryColor={pack.personaB.secondaryColor}
              variant={
                selectedPersona === pack.personaB.id
                  ? 'selected'
                  : 'notSelected'
              }
              onImageClick={() => setSelectedPersona(pack.personaB.id)}
            />
          </Grid>
          <Button
            variant="solid"
            scheme="main.purple"
            onClick={handleSubmitAnswer}
            isDisabled={!selectedPersona}
          >
            submit answer
          </Button>
        </Stack>
      </Stack>
      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
      <Flex sx={styles.button}>
        <LeaveGameButton onClick={handleOpenModal} isAdmin={playerInfo.admin} />
      </Flex>
    </Grid>
  );
};

export default Round;
